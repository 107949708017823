import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
const router = new Router({
  base: '',
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/view/user/login.vue')
    },
    {
      path: '/signUp',
      name: 'signUp',
      component: () => import('@/view/user/sign-up.vue')
    },
    {
      path: '/',
      name: 'main',
      component: () => import('@/view/user/main.vue'),
      redirect: 'home',
      children: [ 
        {
          path: 'home',
          name: 'home',
          component: () => import('@/view/home/index.vue')
        },
        {
          path: 'market',
          name: 'market',
          component: () => import('@/view/market/index.vue')
        },
        {
          path: 'qip',
          name: 'qip',
          component: () => import('@/view/home/page/qip.vue')
        },
        {
          path: 'ipo',
          name: 'ipo',
          component: () => import('@/view/home/page/ipo.vue')
        },
        {
          path: 'ftc',
          name: 'ftc',
          component: () => import('@/view/home/page/ftc.vue')
        },
        {
          path: 'marginTrading',
          name: 'marginTrading',
          component: () => import('@/view/home/page/margin-trading.vue')
        },
        {
          path: 'blockDeal',
          name: 'blockDeal',
          component: () => import('@/view/home/page/block-deal.vue')
        },
        {
          path: 'stockDetail',
          name: 'stockDetail',
          component: () => import('@/view/home/page/detail.vue')
        },
        {
          path: 'portfolio',
          name: 'portfolio',
          component: () => import('@/view/portfolio/index.vue')
        },
        {
          path: 'personal',
          name: 'personal',
          component: () => import('@/view/personal/index.vue'),
          redirect: '/withdrawal',
          children: [ 
            {
              path: '/withdrawal',
              name: 'withdrawal',
              component: () => import('@/view/personal/pages/withdrawal.vue')
            },
            {
              path: '/bankCard',
              name: 'bankCard',
              component: () => import('@/view/personal/pages/bank-card.vue')
            },
            {
              path: '/kyc',
              name: 'kyc',
              component: () => import('@/view/personal/pages/kyc.vue')
            },
            {
              path: '/recharge',
              name: 'recharge',
              component: () => import('@/view/personal/pages/recharge.vue')
            },
            {
              path: '/financial',
              name: 'financial',
              component: () => import('@/view/personal/pages/financial.vue')
            },
            {
              path: '/transaction',
              name: 'transaction',
              component: () => import('@/view/personal/pages/transaction.vue')
            },
            {
              path: '/account',
              name: 'account',
              component: () => import('@/view/personal/pages/account.vue')
            },
          ]
        },
      ]
    }
  ]
});

export default router;