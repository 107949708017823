<template>
  <div id="app"> 
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%; 
}
</style>