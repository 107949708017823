import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import './assets/css/common.scss'
import store from './store'
import router from './router'
import axios from '@/assets/js/axios.js'
import ElementUI from 'element-ui'
import './assets/css/element-variables.scss'

// import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);


const url = 'wss://stock.server.lsb200.com/wss:2333';
// const url = 'wss://server.oak-aindia.com/wss:2333';
let socket = null;
let pingTimer = null;
const webSocketInit = function () {
  socket = new WebSocket(url);
  pingTimer && clearInterval(pingTimer); //重连时清除上一次的ping
  // 连接建立时触发的事件处理程序
  socket.onopen = function () {
    console.log('---collect---');
    window.isConnected = true;
    // 发送消息到服务器
    const params = { type: 'ping' };
    socket.send(JSON.stringify(params));
    pingTimer = setInterval(() => {
      socket.send(JSON.stringify(params));
    }, 10000)
  };
  // 连接关闭时触发的事件处理程序
  socket.onclose = function () {
    window.isConnected = false;
    console.log('---close---');
    // 断线重连
    const timer = setTimeout(() => {
      clearTimeout(timer);
      webSocketInit();
    }, 5000)
  };
  // 发生错误时触发的事件处理程序
  socket.onerror = function (error) {
    window.isConnected = false;
    console.error('---error---', error);
  };
}
webSocketInit();
Vue.prototype.$socket = socket

Vue.config.productionTip = false
Vue.prototype.$axios = axios

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
