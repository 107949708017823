import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import store from '../store'
const i18n = new VueI18n({
    legacy: false,
    locale: store.state.lang,       // 默认语言
    messages: { 
        hi: require('./yd.js'), 
        en: require('./en.js'), 
        cn: require('./zh.js')
    }
});

export default i18n;